<template>
  <div class="main-right-bet">
    <div class="main-right-bet__container__filters">
      <Filters
        :is-mobile="filtersMobileFlag"
        :tournaments="filtersTournaments"
        :is-premium-user="isPremiumUser"
        :game-from-carousel="gameFromCarousel"
        :is-game-loading="isGameLoading"
        @game-change="onGameChange"
        @tournament-change="onTournamentChange"
      />
    </div>
    <hr class="separator separator-filters" />
    <div class="main-right-bet__container__carousel">
      <Carousel
        :is-mobile="carouselMobileFlag"
        :games="carouselGames"
        :selected-game-id="selectedGame.game_id"
        :is-premium-user="isPremiumUser"
        :is-game-loading="isGameLoading"
        @change="onCarouselChange"
        @show-statistics="onShowStatistics"
      />
    </div>
    <div class="main-right-bet__container__analytics">
      <GameAnalysis
        v-if="isGameAnalysisVisible"
        is-inner-component
        :game="selectedGame"
        @close="isGameAnalysisVisible = false"
      />
    </div>
    <div class="main-right-bet__container__section-content">
      <Component
        :is="selectedComponent"
        :is-corners-goals-mobile="cornersGoalsMobileFlag"
        :await-for-bets="awaitForData"
        :parlay-bets="parlayBets"
        :show-logos="selectedTournament ? selectedTournament.showLogos : true"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { cornersGoalsCategories } from '@/data/gol-tipster/categories';
// two hours
const gameTimeLimit = 2 * 60 * 60 * 1000;
import { regularLeague, cup, cupLegacyAbb } from '@/utils/play-off-abbreviations';

export default {
  name: 'Main',
  components: {
    Filters: () => import('@/components/Sections/GolTipster-v2/General/Filters'),
    Carousel: () => import('@/components/Sections/GolTipster-v2/General/CarouselGames'),
    RightBet: () => import('@/components/Sections/GolTipster-v2/RightBet/MainRightBet'),
    GameAnalysis: () => import('@/views/MatchAnalysis'),
  },
  props: {
    awaitForData: {
      type: Boolean,
      default: false,
    },
    rightBetTournaments: {
      type: Array,
      default: () => [],
    },
    parlayBets: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filtersMobileFlag: false,
      carouselMobileFlag: false,
      cornersGoalsMobileFlag: false,
      isGameAnalysisVisible: false,
      selectedComponent: 'RightBet',
      selectedTournament: {
        gamesByRound: [],
      },
      selectedGame: {},
      tournaments: [],
      gameFromCarousel: {},
      gamesWithBets: new Map(),
      isGameLoading: false,
      areSeasonsFetched: false,
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('betsGeneral', ['refreshIntervalCounter']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'isLoggedIn']),
    filtersTournaments() {
      const filtersTournaments = this.tournaments.filter(({ gamesByRound }) => {
        return gamesByRound && gamesByRound.length;
      });
      return filtersTournaments;
    },
    carouselGames() {
      let carouselGames = [];
      if (this.selectedTournament && this.selectedTournament.gamesByRound) {
        carouselGames = this.selectedTournament.gamesByRound.reduce((accumulator, round) => {
          accumulator.push(...round.games);
          return accumulator;
        }, []);
        carouselGames.sort((a, b) => a.dateTime - b.dateTime);
        if (carouselGames.length) {
          carouselGames[0].isFirst = true;
          carouselGames[carouselGames.length - 1].isLast = true;
        }
      }
      return carouselGames;
    },
    isPremiumUser() {
      return this.isLoggedIn && this.getPremiumAccount;
    },
    categoriesQuery() {
      const categoriesQuery = [
        ...cornersGoalsCategories.corners.general,
        ...cornersGoalsCategories.goals.general,
      ].reduce((accumulator, categoryId) => {
        // eslint-disable-next-line no-param-reassign
        accumulator += `c=${categoryId}&`;
        return accumulator;
      }, '');
      return categoriesQuery.substring(0, categoriesQuery.length - 1);
    },
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler(newValue) {
        this.filtersMobileFlag = newValue < 510;
        this.carouselMobileFlag = newValue < 840;
        this.cornersGoalsMobileFlag = newValue < 930;
      },
    },
    isPremiumUser(newValue) {
      if (newValue) {
        this.setSelectedGame({
          awayTeam: {},
          homeTeam: {},
        });
        this.onGameChange(this.selectedGame, true);
      }
    },
    refreshIntervalCounter() {
      this.areSeasonsFetched = false;
    },
    parlayBets: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.tournaments && newValue.tournaments.length) {
          this.setGamesWithBets(newValue);
        }
      },
    },
    rightBetTournaments: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.length) {
          this.setTournamentsData(newValue);
        }
      },
    },
  },
  methods: {
    ...mapMutations('betsGeneral', [
      'setSelectedGame',
      'setGameCornersAndGoalsStats',
      'setLeyendIsPastSeason',
      'setLeyendIsGamesPastSeason',
    ]),
    ...mapActions('betsGeneral', ['fetchTeamStatsBySeason', 'fetchLastFiveGames']),
    async fetchTournaments() {
      try {
        // const url = 'https://cd2rabrly8.execute-api.us-east-2.amazonaws.com/prod/tournaments-right-bet';
        const url = 'https://wiy8vumyh2.execute-api.us-east-2.amazonaws.com/qa/tournaments-right-bet';
        const response = await axios.get(url);
        return response && response.data ? response.data : [];
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchGamesBySeasons(seasons) {
      try {
        const baseUrl = 'https://qyyibs1w0d.execute-api.us-west-2.amazonaws.com/prod/calendar/gamesBySeason';
        const url = seasons.reduce((accumulator, seasonId, index) => {
          // eslint-disable-next-line no-param-reassign
          accumulator += `${index > 0 ? '&' : '?'}seasons=${seasonId}`;
          return accumulator;
        }, baseUrl);
        const response = await axios.get(url);
        return response && response.data ? response.data : [];
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchBets() {
      try {
        // https://3ejrkiihni.execute-api.us-west-2.amazonaws.com/prod/parlays
        const url = 'https://3ph9itfr6f.execute-api.us-east-2.amazonaws.com/qa/parlays';
        const response = await axios.get(url);
        return response && response.data ? response.data : [];
      } catch (e) {
        return Promise.reject(e);
      }
    },
    setGamesWithBets(betsByTournament) {
      this.gamesWithBets = betsByTournament.tournaments.reduce((accumulator, tournament) => {
        tournament.bets.forEach(bet => {
          accumulator.set(bet.game_id, '');
        });
        return accumulator;
      }, new Map());
    },
    async setTournamentsData(tournaments) {
      try {
        if (this.areSeasonsFetched) {
          return;
        }
        const betSectionsLimit = Date.now() + 60 * 1000;
        const leagueMxGamesPromises = [];
        const othersGamesPromises = [];
        tournaments.sort((a, b) => a._id - b._id);
        const tournamentsAux = tournaments.reduce((accumulator, item) => {
          const tournament = {
            id: item.tournament.id,
            name: item.tournament.name,
            isCup: !!item.tournament.tournament_type,
            hasStatistics: !!item.tournament.edition_status,
            showLogos: !!item.tournament.show_logos,
            seasons: [],
          };
          item.seasons.forEach(season => {
            tournament.seasons.push(season.id);
            season.related_seasons.forEach(relatedSeason => {
              tournament.seasons.push(relatedSeason.id);
            });
          });
          if (item._id === 1) {
            leagueMxGamesPromises.push(this.fetchGamesBySeasons(tournament.seasons));
          } else {
            othersGamesPromises.push(this.fetchGamesBySeasons(tournament.seasons));
          }
          accumulator.push(tournament);
          return accumulator;
        }, []);
        this.areSeasonsFetched = true;
        let gamesBySeasons = await Promise.all(leagueMxGamesPromises);
        this.formatGames(gamesBySeasons, tournamentsAux, betSectionsLimit, true);
        this.tournaments = tournamentsAux;
        gamesBySeasons = await Promise.all(othersGamesPromises);
        this.formatGames(gamesBySeasons, tournamentsAux, betSectionsLimit);
        this.tournaments = [...tournamentsAux];
      } catch (e) {
        throw new Error(e);
      }
    },
    formatGames(gamesBySeasons, tournamentsAux, betSectionsLimit, isLeagueMx = false) {
      const now = Date.now();
      gamesBySeasons.forEach(({ data: gamesBySeasons, teams: teamsBySeasons }, index) => {
        if (!isLeagueMx && tournamentsAux[0].id === 1) {
          // eslint-disable-next-line no-param-reassign
          index = index + 1;
        }
        const tournamentTeams = teamsBySeasons.reduce((accumulator, seasonTeams) => {
          if (seasonTeams) {
            seasonTeams.teams.forEach(seasonTeam => {
              if (!accumulator.has(seasonTeam.team_id)) {
                accumulator.set(seasonTeam.team_id, seasonTeam);
              }
            });
          }
          return accumulator;
        }, new Map());
        gamesBySeasons.forEach(seasonGames => {
          const gamesByRound = new Map();
          const tournament = tournamentsAux[index];
          tournament.games = seasonGames.games.filter(game => {
            const gameDateTime = new Date(game.date_time_utc).valueOf();
            if (game.game_status === 4 && gameDateTime + gameTimeLimit > now) {
              game.areBetSectionsVisible = gameDateTime >= betSectionsLimit;
              game.homeTeam = tournamentTeams.get(game.home_team);
              game.awayTeam = tournamentTeams.get(game.visiting_team);
              game.dateTime = gameDateTime;
              game.seasonId = seasonGames.season_id;
              game.isCup = tournament.isCup;
              game.hasBets = this.gamesWithBets.has(game.game_id);
              game.hasStatistics = tournamentsAux[index].hasStatistics;
              const round = gamesByRound.get(game.matchday_abbreviation);
              if (round) {
                round.games.push(game);
              } else {
                gamesByRound.set(game.matchday_abbreviation, {
                  abbreviation: game.matchday_abbreviation,
                  abbreviation2: this.getAbbreviation2(
                    game.matchday_abbreviation,
                    seasonGames.season_type,
                    game.seasonId,
                    game.isCup,
                  ),
                  games: [game],
                });
              }
              return true;
            }
            return false;
          });
          delete tournamentsAux[index].games;
          tournamentsAux[index].gamesByRound = Array.from(gamesByRound.values());
          tournamentsAux[index].gamesByRound.forEach(round => {
            round.games.sort((a, b) => {
              return a.dateTime - b.dateTime;
            });
            const lastGame = round.games[round.games.length - 1];
            if (lastGame) {
              lastGame.isLastGamePerRound = true;
            }
          });
        });
      });
    },
    async fillFiltersAndGames() {
      try {
        const [tournaments, betsByTournament] = await Promise.all([this.fetchTournaments(), this.fetchBets()]);
        this.setGamesWithBets(betsByTournament);
        this.setTournamentsData(tournaments);
      } catch (e) {
        throw new Error(e);
      }
    },
    async fillTeamsStats(game) {
      try {
        if (!game.hasStatistics) {
          this.setLeyendIsPastSeason(false);
          this.setLeyendIsGamesPastSeason(false);
          return null;
        }
        const [homeTeamResponse, awayTeamResponse, lastFiveGames] = await Promise.all([
          this.fetchTeamStatsBySeason({
            seasonId: game.seasonId,
            teamId: game.homeTeam.team_id,
            query: this.categoriesQuery,
          }),
          this.fetchTeamStatsBySeason({
            seasonId: game.seasonId,
            teamId: game.awayTeam.team_id,
            query: this.categoriesQuery,
          }),
          this.fetchLastFiveGames({ gameId: game.game_id }),
        ]);
        game.lastFiveGames = lastFiveGames;
        game.homeLastFiveGames = lastFiveGames.homeTeam || {};
        game.awayLastFiveGames = lastFiveGames.awayTeam || {};
        game.isPastSeasonLastFiveGames = lastFiveGames.isPastGamesSeason;
        this.setLeyendIsGamesPastSeason(lastFiveGames.isPastGamesSeason == 1 ? true : false);
        if (homeTeamResponse && homeTeamResponse.data && awayTeamResponse && awayTeamResponse.data) {
          this.setLeyendIsPastSeason(homeTeamResponse.is_past_season == 1 ? true : false);
          return {
            homeTeam: {
              stats: homeTeamResponse.data.filter(stat => stat[0] === 1 || stat[0] === 53),
              games: homeTeamResponse.games,
            },
            awayTeam: {
              stats: awayTeamResponse.data.filter(stat => stat[0] === 1 || stat[0] === 53),
              games: awayTeamResponse.games,
            },
            isPastSeason: homeTeamResponse.is_past_season,
          };
        }
        return null;
      } catch (e) {
        throw new Error(e);
      }
    },

    getAbbreviation2(abbreviation, seasonType, seasonId, isCup) {
      // eslint-disable-next-line no-param-reassign
      abbreviation = abbreviation.toUpperCase();
      let abbreviation2 = abbreviation.replace('M', 'J');
      if (isCup) {
        if (seasonId === 881) {
          const roundNumberString = abbreviation.replace('M', '');
          abbreviation2 = cupLegacyAbb[roundNumberString] || abbreviation2;
        } else {
          abbreviation2 = cup[abbreviation] ? abbreviation : abbreviation2;
        }
      } else {
        if (seasonType === 2) {
          abbreviation2 = `R${abbreviation.replace('PO', '')}`;
        } else {
          abbreviation2 = regularLeague[abbreviation] || abbreviation2;
        }
      }
      return abbreviation2;
    },
    async onGameChange(game, skipValidation = false) {
      try {
        this.isGameLoading = true;
        if (this.selectedGame.game_id !== game.game_id || skipValidation) {
          if (!game.cornersAndGoalsStats || skipValidation) {
            game.cornersAndGoalsStats = await this.fillTeamsStats(game);
          } else {
            this.setLeyendIsGamesPastSeason(game.isPastSeasonLastFiveGames == 1 ? true : false);
            this.setLeyendIsPastSeason(game.cornersAndGoalsStats.isPastSeason == 1 ? true : false);
          }

          this.selectedGame = game;
          this.setSelectedGame(game);
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        this.isGameLoading = false;
      }
    },
    onTournamentChange(tournament) {
      this.isGameAnalysisVisible = false;
      this.selectedTournament = tournament;
      this.$emit('tournament-change', tournament);
    },
    onCarouselChange(game) {
      this.gameFromCarousel = game;
      this.onGameChange(game);
    },
    onShowStatistics() {
      this.isGameAnalysisVisible = true;
    },
  },
  mounted() {
    if (!this.awaitForData) {
      this.fillFiltersAndGames();
    }
  },
};
</script>

<style scoped lang="scss">
.main-right-bet {
  &__container {
    max-width: 64.5rem;
    width: 100%;
    padding: 0 0.5rem;

    &__filters {
      margin: 1.5rem 0 0.5rem;
      text-align: left;
      width: 100%;

      //@media screen and (max-width: 486px) {
      //  margin: 1rem 0.4rem 0;
      //}
    }

    &__carousel {
      margin: 1rem 0;
      border-radius: 24px;
      box-shadow: 0 15px 26px rgba(59, 70, 171, 0.31);
      background-image: url('/images/base-desktop.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      min-height: 12.5rem;

      @media screen and (max-width: 839px) {
        min-height: 11.25rem;
        background-image: url('/images/base-mobile.png');
      }

      @media screen and (max-width: 530px) {
        min-height: 9.75rem;
      }

      @media screen and (max-width: 486px) {
        margin: 0 0.4rem;
      }

      @media screen and (max-width: 460px) {
        min-height: 9.25rem;
      }
    }

    &__section-content {
      width: 100%;
      padding: 0 1rem;
      margin-top: 2rem;

      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }

    @media screen and (max-width: 486px) {
      padding: 0;
    }
  }
}

.separator {
  border-top: dashed 1px #94a8ba;
  margin: 0;

  &-filters {
    @media screen and (max-width: 510px) {
      display: none;
    }
  }
}
</style>
